import React from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import VideoDetail from "../components/Video404"

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Video404 from "../assets/videos/Turboram/turboram-lavagem.mp4"


const NotFoundPage = ({ location, data }) => {
  const {t} = useTranslation()

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
        <SEO title="Ramalhos - Pure Baking Pleasure" titleTemplate="%s"/>
        <VideoDetail 
            src={Video404}
            id="video-404"
            _title={t('title')}
            text1={t('text1')}
            text2={t('text2')}
            positionCenter
            buttonLink={t('buttonLink')}
            buttonText={t('buttonText')}
        />
    </Layout>
  )
}

export default NotFoundPage

export const JsonPT = graphql`
    query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["404", "footer", "header"]}, language: {eq: $language}}) {
            edges {
            node {
                ns
                data
                language
            }
            }
        }
    }
`